var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-filter" },
    [
      _vm.dropType === "select"
        ? _c(
            "a-select",
            {
              attrs: {
                disabled: _vm.disabled,
                "show-search": "",
                placeholder: _vm.placeHolder,
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.labelValues, function (item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _c("a-auto-complete", {
            attrs: {
              disabled: _vm.disabled,
              "data-source": _vm.dataSource,
              placeholder: _vm.placeHolder,
              "filter-option": _vm.filterOption,
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }