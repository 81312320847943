(
<template>
  <div class="select-filter">
    <a-select v-if="dropType === 'select'" v-model="value" :disabled="disabled" show-search :placeholder="placeHolder" option-filter-prop="children" :filter-option="filterOption" @change="handleChange">
      <a-select-option :value="item.value" v-for="item in labelValues" :key="item.value"> {{ item.label }} </a-select-option>
    </a-select>
    <a-auto-complete v-else v-model="value" :disabled="disabled" :data-source="dataSource" :placeholder="placeHolder" :filter-option="filterOption" @change="handleChange" />
  </div>
</template>
<script>
import { AutoComplete } from 'ant-design-vue'
export default {
  name: 'SelectFilter',
  components: { AAutoComplete: AutoComplete },
  props: {
    placeHolder: {
      type: String,
      default: undefined
    },
    labelValues: {
      type: Array,
      default: () => {}
    },
    value: {
      type: String,
      default: undefined
    },
    dropType: {
      type: String,
      default: 'select'
    },
    dataSource: {
      type: Array,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
<style lang="less">
.select-filter {
  .ant-select {
    width: 100%;
  }
}
</style>
