var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "车辆编号", prop: "vehicleId" } },
                            [
                              _c("a-tree-select", {
                                staticClass: "my-test",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  replaceFields: _vm.replaceFields,
                                  "show-search": "",
                                  "tree-data": _vm.treeData,
                                  "tree-node-filter-prop": "title",
                                  placeholder: "请选择车辆",
                                  dropdownStyle: { maxHeight: 500 + "px" },
                                },
                                on: { focus: _vm.treeFocus },
                                model: {
                                  value: _vm.queryParam.vehicleId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "vehicleId", $$v)
                                  },
                                  expression: "queryParam.vehicleId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "状态", prop: "state" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.queryParam.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "status", $$v)
                                    },
                                    expression: "queryParam.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "0" } },
                                    [_vm._v(" 未分类 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "1" } },
                                    [_vm._v(" 已分类 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 6, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "发生时间" } },
                            [
                              _c("a-range-picker", {
                                attrs: {
                                  format: "YYYY-MM-DD",
                                  "value-format": "YYYY-MM-DD",
                                  placeholder: ["开始日期", "结束日期"],
                                },
                                model: {
                                  value: _vm.takeoverTimeRange,
                                  callback: function ($$v) {
                                    _vm.takeoverTimeRange = $$v
                                  },
                                  expression: "takeoverTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: { label: "处理人", prop: "handler" },
                                  },
                                  [
                                    _c("select-filter", {
                                      attrs: {
                                        "place-holder": "全部",
                                        "drop-type": "auto",
                                        dataSource: _vm.userDataSource,
                                      },
                                      model: {
                                        value: _vm.queryParam.operator,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "operator",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.operator",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: { label: "介入类型", prop: "state" },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择介入类型",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.takeoverType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "takeoverType",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.takeoverType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.takeoverTypeOptions,
                                        function (d, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index,
                                              attrs: { value: d.dictValue },
                                            },
                                            [_vm._v(_vm._s(d.dictLabel))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 6) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("work-order-edit", {
            ref: "workOrderEdit",
            attrs: { stateOptions: _vm.stateOptions },
            on: { ok: _vm.getList },
          }),
          _c("a-table", {
            attrs: {
              tid: "1",
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "takeoverType",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.takeoverTypeFormat(record)) + " "),
                  ])
                },
              },
              {
                key: "status",
                fn: function (text, record) {
                  return _c("span", { staticClass: "state" }, [
                    _c("span", {
                      staticClass: "state-point",
                      class: [
                        record.status === 0
                          ? "state-point--red"
                          : "state-point--blue",
                      ],
                    }),
                    _vm._v(
                      _vm._s(record.status === 0 ? "未分类" : "已分类") + " "
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c("span", {}, [
                    record.status !== 1
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$refs.takeoverRef.handleUpdate(
                                  record
                                )
                              },
                            },
                          },
                          [_vm._v("分类 ")]
                        )
                      : _vm._e(),
                  ])
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
      _c("takeover-edit", {
        ref: "takeoverRef",
        attrs: { takeoverTypeOptions: _vm.takeoverTypeOptions.slice(1) },
        on: { ok: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }