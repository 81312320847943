<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-tree-select
                  class="my-test"
                  v-model="queryParam.vehicleId"
                  :replaceFields="replaceFields"
                  show-search
                  style="width: 100%"
                  :tree-data="treeData"
                  @focus="treeFocus"
                  tree-node-filter-prop="title"
                  placeholder="请选择车辆"
                  :dropdownStyle="{maxHeight: 500+'px'}"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态" prop="state">
                <a-select v-model="queryParam.status">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="0"> 未分类 </a-select-option>
                  <a-select-option value="1"> 已分类 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="发生时间">
                <a-range-picker
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :placeholder="['开始日期', '结束日期']"
                  v-model="takeoverTimeRange"
                />
              </a-form-item>
            </a-col>

            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="处理人" prop="handler">
                  <select-filter
                    v-model="queryParam.operator"
                    place-holder="全部"
                    drop-type="auto"
                    :dataSource="userDataSource"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="介入类型" prop="state">
                  <a-select
                    placeholder="请选择介入类型"
                    v-model="queryParam.takeoverType"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in takeoverTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 6) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <work-order-edit ref="workOrderEdit" :stateOptions="stateOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        tid="1"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="takeoverType" slot-scope="text, record">
          {{ takeoverTypeFormat(record) }}
        </span>
        <span class="state" slot="status" slot-scope="text, record">
          <span class="state-point" :class="[record.status === 0 ? 'state-point--red' : 'state-point--blue']"></span
          >{{ record.status === 0 ? '未分类' : '已分类' }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.takeoverRef.handleUpdate(record)" v-if="record.status !==1">分类 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <takeover-edit @ok="getList" :takeoverTypeOptions="takeoverTypeOptions.slice(1)" ref="takeoverRef"/>
  </page-header-wrapper>

</template>

<script>
 import { listTakeOver } from '@/api/iot/takeover'
import { listUser } from '@/api/system/user'
import SelectFilter from '@/views/monitor/monitorForm/vehicle/funcList/modules/SelectFilter'
import { TreeSelect } from 'ant-design-vue'
import { getVehicleTree } from '@/api/iot/vehicle'
import TakeoverEdit from './modules/TakeoverEdit'

export default {
  name: 'WorkOrder',
  components: {
    // WorkOrderEdit,
    SelectFilter,
    ATreeSelect: TreeSelect,
    TakeoverEdit
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      treeData: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 工单状态(字典)：1，待处理；2，处理完成字典
      stateOptions: [],
      // 日期范围
      takeoverTimeRange: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        status: '',
        takeoverTime: null,
        takeoverType: '',
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '介入类型',
          dataIndex: 'takeoverType',
          scopedSlots: { customRender: 'takeoverType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理人',
          dataIndex: 'operator',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发生时间',
          dataIndex: 'takeoverTime',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      handlerLabelValues: [],
      orderTypeLabelValues: [],
      takeoverTypeOptions: []
    }
  },
  filters: {},
  created() {
    this.initVehicleTree()
    this.getList()
    this.getDicts('iot_vehicle_order_status').then((response) => {
      this.stateOptions = response.data
    })
    this.getDicts('vehicle_takeover_type').then((response) => {
      this.takeoverTypeOptions = response.data
      this.takeoverTypeOptions.unshift({ dictValue: '', dictLabel: '全部' })
    })
    this.getUserList()
  },
  computed: {},
  watch: {},
  methods: {
    takeoverTypeFormat(row, column) {
      return this.selectDictLabel(this.takeoverTypeOptions, row.takeoverType + '')
    },
    initVehicleTree() {
      getVehicleTree().then((res) => {
        if (res.data) {
          this.treeData = res.data
        }
      })
    },
    getUserList() {
      listUser({}).then((response) => {
        const userLabelValues = []
        const rows = response.rows
        if (rows && rows.length > 0) {
          rows.forEach((p) => {
            userLabelValues.push({
              label: p.userName,
              value: p.userName
            })
          })
        }
        this.orderTypeLabelValues = [
          {
            label: '全部',
            value: ''
          },
          {
            label: '自动',
            value: 1
          },
          ...userLabelValues
        ]
        this.userDataSource = userLabelValues.map((p) => p.value)
      })
    },
    /** 查询车辆工单信息列表 */
    getList() {
      this.loading = true
      if (this.queryParam.vehicleId) {
        if (this.queryParam.vehicleId.indexOf('t-1-') >= 0) {
          this.queryParam.vehicleId = this.queryParam.vehicleId.substring(4)
        }
      }
      this.queryParam.params = {}
      if (
        this.takeoverTimeRange !== null &&
        this.takeoverTimeRange !== '' &&
        this.takeoverTimeRange.length !== 0
      ) {
        this.queryParam.params['beginTakeoverTime'] = this.takeoverTimeRange[0]
        this.queryParam.params['endTakeoverTime'] = this.takeoverTimeRange[1]
      }
      listTakeOver(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 工单状态(字典)：1，待处理；2，处理完成字典翻译
    stateFormat(row, column) {
      return this.selectDictLabel(this.stateOptions, row.state)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.queryParam.createBy = ''
      if (this.queryParam.orderType && this.queryParam.orderType !== 1) {
        this.queryParam.createBy = this.queryParam.orderType
        this.queryParam.orderType = ''
      }
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.takeoverTimeRange = []
      this.queryParam = {
        vehicleId: undefined,
        warnId: undefined,
        orderType: undefined,
        state: undefined,
        handler: undefined,
        createTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
</script>
<style lang="less" scoped>
.state {
  display: flex;
  justify-content: center;
  align-items: center;
  .state-point {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 2px;
  }

  .state-point--blue {
    background-color: #0e77d1;
  }
  .state-point--red {
    background-color: #b8741a;
  }
}
</style>
